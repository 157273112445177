import srcShadow from './images/shadow.png';
import srcC from './images/c.webp';
import srcBankDirect from './images/smartpay-bank-direct.png';

export default {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.0.2', a: '', k: '', d: '', tc: '' },
  fr: 30,
  ip: 0,
  op: 90,
  w: 1110,
  h: 710,
  nm: 'C',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 640,
      h: 170,
      u: '',
      p: srcBankDirect,
      e: 0,
    },
    { id: 'image_1', w: 656, h: 272, u: '', p: srcShadow, e: 0 },
    { id: 'image_2', w: 608, h: 608, u: '', p: srcC, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'smartpay-bank-direct.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 5, s: [0] },
            { t: 35, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 5,
                s: [322],
              },
              { t: 35, s: [382] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 500, ix: 4 },
        },
        a: { a: 0, k: [320, 85, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 5,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'shadow.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 5, s: [0] },
            { t: 35, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 5,
                s: [322],
              },
              { t: 35, s: [382] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 522.503, ix: 4 },
        },
        a: { a: 0, k: [328, 136, 0], ix: 1 },
        s: { a: 0, k: [107.857, 86.932, 100], ix: 6 },
      },
      ao: 0,
      ip: 5,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'C_2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 3, s: [0] },
            { t: 33, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 3,
                s: [1035.04],
              },
              { t: 33, s: [959.04] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 429.281, ix: 4 },
        },
        a: { a: 0, k: [959.04, 429.281, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.381],
                    [1.38, 0],
                    [0, 0],
                    [0, 1.381],
                    [-1.381, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 1.381],
                    [0, 0],
                    [-1.381, 0],
                    [0, -1.381],
                    [0, 0],
                    [1.38, 0],
                  ],
                  v: [
                    [8.88, 0],
                    [6.38, 2.5],
                    [-6.38, 2.5],
                    [-8.88, 0],
                    [-6.38, -2.5],
                    [6.38, -2.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [1003.44, 454.871], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.381],
                    [1.38, 0],
                    [0, 0],
                    [0, 1.381],
                    [-1.381, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 1.381],
                    [0, 0],
                    [-1.381, 0],
                    [0, -1.381],
                    [0, 0],
                    [1.38, 0],
                  ],
                  v: [
                    [8.88, 0],
                    [6.38, 2.5],
                    [-6.38, 2.5],
                    [-8.88, 0],
                    [-6.38, -2.5],
                    [6.38, -2.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [979.761, 454.871], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.381],
                    [1.381, 0],
                    [0, 0],
                    [0, 1.381],
                    [-1.38, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 1.381],
                    [0, 0],
                    [-1.38, 0],
                    [0, -1.381],
                    [0, 0],
                    [1.381, 0],
                  ],
                  v: [
                    [8.88, 0],
                    [6.38, 2.5],
                    [-6.38, 2.5],
                    [-8.88, 0],
                    [-6.38, -2.5],
                    [6.38, -2.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [956.079, 454.871], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.381],
                    [1.381, 0],
                    [0, 0],
                    [0, 1.381],
                    [-1.381, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 1.381],
                    [0, 0],
                    [-1.381, 0],
                    [0, -1.381],
                    [0, 0],
                    [1.381, 0],
                  ],
                  v: [
                    [8.879, 0],
                    [6.379, 2.5],
                    [-6.379, 2.5],
                    [-8.879, 0],
                    [-6.379, -2.5],
                    [6.379, -2.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [932.399, 454.871], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.381],
                    [1.38, 0],
                    [0, 0],
                    [0, 1.381],
                    [-1.381, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 1.381],
                    [0, 0],
                    [-1.381, 0],
                    [0, -1.381],
                    [0, 0],
                    [1.38, 0],
                  ],
                  v: [
                    [71.04, 0],
                    [68.54, 2.5],
                    [-68.54, 2.5],
                    [-71.04, 0],
                    [-68.54, -2.5],
                    [68.54, -2.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [959.04, 421.187], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.38],
                    [1.38, 0],
                    [0, 0],
                    [0, 1.381],
                    [-1.381, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 1.381],
                    [0, 0],
                    [-1.381, 0],
                    [0, -1.38],
                    [0, 0],
                    [1.38, 0],
                  ],
                  v: [
                    [71.04, 0],
                    [68.54, 2.5],
                    [-68.54, 2.5],
                    [-71.04, 0],
                    [-68.54, -2.5],
                    [68.54, -2.5],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [959.04, 404.021], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -5.247],
                    [0, 0],
                    [-5.247, 0],
                    [0, 0],
                    [0, 5.247],
                    [0, 0],
                    [5.25, 0],
                  ],
                  o: [
                    [-5.247, 0],
                    [0, 0],
                    [0, 5.247],
                    [0, 0],
                    [5.25, 0],
                    [0, 0],
                    [0, -5.247],
                    [0, 0],
                  ],
                  v: [
                    [-59.04, -43.781],
                    [-68.54, -34.281],
                    [-68.54, 34.281],
                    [-59.04, 43.781],
                    [59.04, 43.781],
                    [68.54, 34.281],
                    [68.54, -34.281],
                    [59.04, -43.781],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-8.008, 0],
                    [0, 0],
                    [0, -8.008],
                    [0, 0],
                    [8.01, 0],
                    [0, 0],
                    [0, 8.009],
                  ],
                  o: [
                    [0, -8.008],
                    [0, 0],
                    [8.01, 0],
                    [0, 0],
                    [0, 8.009],
                    [0, 0],
                    [-8.008, 0],
                    [0, 0],
                  ],
                  v: [
                    [-73.54, -34.281],
                    [-59.04, -48.781],
                    [59.04, -48.781],
                    [73.54, -34.281],
                    [73.54, 34.281],
                    [59.04, 48.781],
                    [-59.04, 48.781],
                    [-73.54, 34.281],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [959.04, 429.281], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 3,
      op: 91,
      st: -297,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'C_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 1, s: [0] },
            { t: 31, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 1,
                s: [949.492],
              },
              { t: 31, s: [849.492] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 223.393, ix: 4 },
        },
        a: { a: 0, k: [849.492, 223.393, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-12.993, 0],
                    [0, -12.993],
                    [12.993, 0],
                    [0, 12.993],
                  ],
                  o: [
                    [12.993, 0],
                    [0, 12.993],
                    [-12.993, 0],
                    [0, -12.993],
                  ],
                  v: [
                    [0, -23.526],
                    [23.526, 0],
                    [0, 23.526],
                    [-23.526, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.247058823705, 0.560784339905, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [879.493, 223.393], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-12.993, 0],
                    [0, -12.993],
                    [12.993, 0],
                    [0, 12.993],
                  ],
                  o: [
                    [12.993, 0],
                    [0, 12.993],
                    [-12.993, 0],
                    [0, -12.993],
                  ],
                  v: [
                    [0, -23.526],
                    [23.526, 0],
                    [0, 23.526],
                    [-23.526, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.247058823705, 0.560784339905, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [847.493, 223.393], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-12.993, 0],
                    [0, -12.993],
                    [12.993, 0],
                    [0, 12.993],
                  ],
                  o: [
                    [12.993, 0],
                    [0, 12.993],
                    [-12.993, 0],
                    [0, -12.993],
                  ],
                  v: [
                    [0, -23.526],
                    [23.526, 0],
                    [0, 23.526],
                    [-23.526, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.247058823705, 0.560784339905, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [819.493, 223.393], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.627, 0],
                    [0, 0],
                    [0, -6.627],
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.627],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [6.627, 0],
                    [0, 0],
                    [0, 6.627],
                    [0, 0],
                    [-6.627, 0],
                    [0, 0],
                    [0, -6.627],
                  ],
                  v: [
                    [-89.492, -63.393],
                    [89.492, -63.393],
                    [101.492, -51.393],
                    [101.492, 51.393],
                    [89.492, 63.393],
                    [-89.492, 63.393],
                    [-101.492, 51.393],
                    [-101.492, -51.393],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.247058823705, 0.560784339905, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [849.492, 223.393], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 1,
      op: 91,
      st: -299,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'C.webp',
      cl: 'webp',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [555, 355, 0], ix: 2 },
        a: { a: 0, k: [304, 304, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [23.748, 0],
                [0, 0],
                [0, -23.748],
                [0, 0],
                [-23.748, 0],
                [0, 0],
                [0, 23.748],
                [0, 0],
              ],
              o: [
                [0, 0],
                [-23.748, 0],
                [0, 0],
                [0, 23.748],
                [0, 0],
                [23.748, 0],
                [0, 0],
                [0, -23.748],
              ],
              v: [
                [565, 0],
                [43, 0],
                [0, 43],
                [0, 564],
                [43, 607],
                [565, 607],
                [608, 564],
                [608, 43],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      ip: 0,
      op: 90,
      st: -300,
      bm: 0,
    },
  ],
  markers: [],
};
