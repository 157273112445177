import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Rellax from 'rellax';
import srcHeroStatus from '../assets/index-2/hero-status.svg';
import BankDirect from '../components/BankDirect/BankDirect';
import ConsumerMobileApp from '../components/ConsumerMobileApp/ConsumerMobileApp';
import FeaturedShops from '../components/FeaturedShops/FeaturedShops';
import Hiroyuki from '../components/Hiroyuki/Hiroyuki';
import Why from '../components/IndexPage/v2/Why';
import Layout from '../components/Layout/Layout';
import { News } from '../components/News/NewsRow';
import NewsPreview from '../components/NewsPreview/NewsPreview';
import { StripType } from '../types';
import useImageQuery from '../utils/use-image-query';
import styles from './index.module.scss';

interface Props {
  data: {
    locales: object;
    contentful: {
      newsCollection: { items: News[] };
      stripCollection: { items: StripType[] };
    };
  };
}

export const indexPageQuery = graphql`
  query Index2PageQuery($language: String!, $dev: Boolean!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      newsCollection(
        order: [date_DESC]
        locale: $language
        where: { slug_exists: true, cover_exists: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          content {
            json
          }
          categoryCollection(limit: 5) {
            items {
              title(locale: $language)
              slug
            }
          }
          merchantCollection(limit: 5) {
            items {
              title(locale: $language)
            }
          }
          publisherCollection(limit: 5) {
            items {
              title(locale: $language)
            }
          }
          slug
          title(locale: $language)
          excerpt(locale: $language)
          date
          cover {
            url
            width
            height
          }
          coverMobile {
            url
            width
            height
          }
          isHero
        }
      }
    }
  }
`;

const HeroFigure = () => {
  const { i18n } = useI18next();
  const winWidth = useWindowWidth();
  const isMobile = winWidth < 768;

  const [parallaxDisabled, setParallaxDisabled] = useState(true);
  const [heroStateScreen, setHeroStateScreen] = useState('inactive');
  const [heroStateThumbL, setHeroStateThumbL] = useState('inactive');
  const [heroStateThumbM, setHeroStateThumbM] = useState('inactive');
  const [heroStateThumbS, setHeroStateThumbS] = useState('inactive');
  const [heroStateStatus, setHeroStateStatus] = useState('inactive');

  const refParallaxScreen = useRef<HTMLImageElement>(null);
  const refParallaxThumbL = useRef<HTMLImageElement>(null);
  const refParallaxThumbM = useRef<HTMLImageElement>(null);
  const refParallaxThumbS = useRef<HTMLImageElement>(null);
  const refParallaxStatus = useRef<HTMLImageElement>(null);

  const refIntroAnchor = useRef<HTMLDivElement>(null);

  const {
    srcIndexHeroThumbL,
    srcIndexHeroThumbM,
    srcIndexHeroThumbS,
    srcAppScreenHomeEn,
    srcAppScreenHomeJa,
  } = useImageQuery();

  const srcHeroScreen =
    i18n.language === 'en' ? srcAppScreenHomeEn : srcAppScreenHomeJa;

  useEffect(() => {
    const callback = (event: any) => {
      if (event.propertyName === 'opacity') {
        setParallaxDisabled(false);
      }
    };

    refIntroAnchor.current?.addEventListener('transitionend', callback);

    return () => {
      refIntroAnchor.current?.removeEventListener(
        'transitionend',
        callback,
        false
      );
    };
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      setHeroStateScreen('active');
    }, 1);
    setTimeout(() => {
      setHeroStateThumbL('active');
    }, 200);
    setTimeout(() => {
      setHeroStateThumbM('active');
    }, 400);
    setTimeout(() => {
      setHeroStateThumbS('active');
    }, 600);
    setTimeout(() => {
      setHeroStateStatus('active');
    }, 800);
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (refParallaxScreen.current) {
        refParallaxScreen.current.style.transform = '';
      }
      if (refParallaxThumbL.current) {
        refParallaxThumbL.current.style.transform = '';
      }
      if (refParallaxThumbM.current) {
        refParallaxThumbM.current.style.transform = '';
      }
      if (refParallaxThumbS.current) {
        refParallaxThumbS.current.style.transform = '';
      }
      if (refParallaxStatus.current) {
        refParallaxStatus.current.style.transform = '';
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (!parallaxDisabled) {
      refParallaxScreen.current &&
        new Rellax(refParallaxScreen.current, { speed: 2 });
      refParallaxThumbL.current &&
        new Rellax(refParallaxThumbL.current, { speed: 1 });
      refParallaxThumbM.current &&
        new Rellax(refParallaxThumbM.current, { speed: 3 });
      refParallaxThumbS.current &&
        new Rellax(refParallaxThumbS.current, { speed: 2 });
      refParallaxStatus.current &&
        new Rellax(refParallaxStatus.current, { speed: 4 });
    }
  }, [parallaxDisabled]);

  return (
    <div className={styles.heroFigure} role="presentation">
      <div
        className={cx(
          styles.heroScreen,
          styles[heroStateScreen],
          !parallaxDisabled && styles.static
        )}
      >
        <figure
          ref={refParallaxScreen}
          style={{ borderRadius: 32, overflow: 'hidden' }}
        >
          <GatsbyImage
            image={srcHeroScreen.childImageSharp.gatsbyImageData}
            alt=""
          />
        </figure>
      </div>
      <div
        className={cx(
          styles.heroThumbL,
          styles[heroStateThumbL],
          !parallaxDisabled && styles.static
        )}
      >
        <figure ref={refParallaxThumbL}>
          <GatsbyImage
            image={srcIndexHeroThumbL.childImageSharp.gatsbyImageData}
            alt=""
          />
        </figure>
      </div>
      <div
        className={cx(
          styles.heroThumbM,
          styles[heroStateThumbM],
          !parallaxDisabled && styles.static
        )}
      >
        <figure ref={refParallaxThumbM}>
          <GatsbyImage
            image={srcIndexHeroThumbM.childImageSharp.gatsbyImageData}
            alt=""
          />
        </figure>
      </div>
      <div
        className={cx(
          styles.heroThumbS,
          styles[heroStateThumbS],
          !parallaxDisabled && styles.static
        )}
      >
        <figure ref={refParallaxThumbS}>
          <GatsbyImage
            image={srcIndexHeroThumbS.childImageSharp.gatsbyImageData}
            alt=""
          />
        </figure>
      </div>
      <div
        className={cx(
          styles.heroStatus,
          styles[heroStateStatus],
          !parallaxDisabled && styles.static
        )}
        ref={refIntroAnchor}
      >
        <img src={srcHeroStatus} alt="" ref={refParallaxStatus} />
      </div>
    </div>
  );
};

const IndexPage: React.FC<Props> = ({ data }) => {
  const { t, originalPath, i18n } = useI18next();
  const {
    contentful: {
      newsCollection: { items: newsList },
      stripCollection: { items: strips },
    },
  } = data;

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      rootClassName={styles.index}
      SEOProps={{
        meta: [
          {
            name: 'apple-itunes-app',
            content: 'app-id=1578056765',
          },
        ],
      }}
      strips={strips}
    >
      <section id="hero" className={styles.heroContainer}>
        <div className={styles.box}>
          <div className={styles.content}>
            <h1>
              {<Trans i18nKey="homepage-2-title" components={{ br: <br /> }} />}
            </h1>
            <p>
              {
                <Trans
                  i18nKey="homepage-2-subtitle"
                  components={{ br: <br /> }}
                />
              }
            </p>

            <div className={styles.heroActions}>
              <Link className={cx(styles.action)} to={'/consumer'}>
                {t('homepage-2-for-shoppers')}
              </Link>
              <Link
                className={cx(styles.action, styles.forBusiness)}
                to={'/merchant'}
              >
                {t('homepage-2-for-businesses')}
              </Link>
            </div>

            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.heroArrow}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.7145 22.5976L20.5498 27.7622C20.2329 28.0792 19.7191 28.0792 19.4021 27.7622L14.2375 22.5976C13.9206 22.2807 13.9206 21.7668 14.2375 21.4499C14.5544 21.133 15.0683 21.133 15.3852 21.4499L19.1644 25.2292L19.1644 12.8115C19.1644 12.3633 19.5278 12 19.976 12C20.4242 12 20.7875 12.3633 20.7875 12.8115L20.7875 25.2292L24.5668 21.4499C24.8837 21.133 25.3975 21.133 25.7145 21.4499C26.0314 21.7669 26.0314 22.2807 25.7145 22.5976Z"
                fill="#3a3262"
              />
            </svg>
          </div>
        </div>
        <HeroFigure />
      </section>
      <Why />
      <BankDirect className={styles.bankDirect} />
      <Hiroyuki />
      <FeaturedShops className={styles.featuredShops} />
      <ConsumerMobileApp className={styles.consumerMobileApp} />
      <NewsPreview data={newsList} />
    </Layout>
  );
};

export default IndexPage;
