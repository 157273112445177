import loadable from '@loadable/component';
import cx from 'classnames';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { LottieRefCurrentProps } from 'lottie-light-react';
import { useRef } from 'react';
import { Trans } from 'react-i18next';
import animationIndexWhy1 from '../../../assets/lottie/index-why/a/a';
import animationIndexWhy2En from '../../../assets/lottie/index-why/b/b-en';
import animationIndexWhy2Ja from '../../../assets/lottie/index-why/b/b-ja';
import animationIndexWhy3 from '../../../assets/lottie/index-why/c/c';
import styles from '../../../pages/index.module.scss';
import useStickyProgress from '../../../utils/use-sticky-progress';

const LottieWrapper = loadable.lib(() => import('lottie-light-react'));

const Why = () => {
  const { t, i18n } = useI18next();
  const lottieRef1 = useRef<LottieRefCurrentProps>(null);
  const lottieRef2 = useRef<LottieRefCurrentProps>(null);
  const lottieRef3 = useRef<LottieRefCurrentProps>(null);

  const { ref: refWhy1 } = useStickyProgress({
    effect: ({ progressIn }) => {
      if (progressIn > 0.25) {
        lottieRef1.current?.play();
      }
    },
  });

  const { ref: refWhy2 } = useStickyProgress({
    effect: ({ progressIn }) => {
      if (progressIn > 0.25) {
        lottieRef2.current?.play();
      }
    },
  });

  const { ref: refWhy3 } = useStickyProgress({
    effect: ({ progressIn }) => {
      if (progressIn > 0.25) {
        lottieRef3.current?.play();
      }
    },
  });

  return (
    <section id="why" className={cx(styles.container, styles.whyContainer)}>
      <div className={cx(styles.box)}>
        <div className={cx(styles.content)}>
          <h2>
            {
              <Trans
                i18nKey="homepage-2-why-choose-smartpay"
                components={{ br: <br /> }}
              />
            }
          </h2>
          <div className={cx(styles.whySection, styles.first)}>
            <div className={styles.figure} ref={refWhy1}>
              <LottieWrapper>
                {({ default: Lottie }) => (
                  <Lottie
                    lottieRef={lottieRef1}
                    animationData={animationIndexWhy1}
                    autoplay={false}
                    loop={false}
                  />
                )}
              </LottieWrapper>
            </div>
            <div className={styles.content}>
              <h3>
                {
                  <Trans
                    i18nKey="homepage-2-3-interest-free-payments"
                    components={{ br: <br /> }}
                  />
                }
              </h3>
              <p>{t('homepage-2-3-interest-free-payments-desc')}</p>
            </div>
          </div>
          <div className={cx(styles.whySection, styles.second)}>
            <div className={styles.figure} ref={refWhy2}>
              <LottieWrapper>
                {({ default: Lottie }) => (
                  <Lottie
                    lottieRef={lottieRef2}
                    animationData={
                      i18n.language === 'en'
                        ? animationIndexWhy2En
                        : animationIndexWhy2Ja
                    }
                    autoplay={false}
                    loop={false}
                    // isPaused={isWhy2LottiePaused}
                  />
                )}
              </LottieWrapper>
            </div>
            <div className={styles.content}>
              <h3>
                {
                  <Trans
                    i18nKey="homepage-2-1-click-payment"
                    components={{ br: <br /> }}
                  />
                }
              </h3>
              <p>{t('homepage-2-1-click-payment-desc')}</p>
            </div>
          </div>
          <div className={cx(styles.whySection, styles.third)}>
            <div className={styles.figure} ref={refWhy3}>
              <LottieWrapper>
                {({ default: Lottie }) => (
                  <Lottie
                    lottieRef={lottieRef3}
                    animationData={animationIndexWhy3}
                    autoplay={false}
                    loop={false}
                  />
                )}
              </LottieWrapper>
            </div>
            <div className={styles.content}>
              <h3>
                {
                  <Trans
                    i18nKey="homepage-2-2-ways-to-pay"
                    components={{ br: <br /> }}
                  />
                }
              </h3>
              <p>{t('homepage-2-2-ways-to-pay-desc')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
