import srcShadow from './images/shadow.png';
import srcCoffeeMakerJa from './images/coffee-maker-ja.png';
import srcBoatMarket from './images/style-boat-market.webp';

export default {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.0.2', a: '', k: '', d: '', tc: '' },
  fr: 30,
  ip: 0,
  op: 90,
  w: 1110,
  h: 710,
  nm: 'B-ja',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 562,
      h: 260,
      u: '',
      p: srcCoffeeMakerJa,
      e: 0,
    },
    { id: 'image_1', w: 626, h: 324, u: '', p: srcShadow, e: 0 },
    {
      id: 'image_2',
      w: 680,
      h: 680,
      u: '',
      p: srcBoatMarket,
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'coffee-maker-ja.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 5, s: [0] },
            { t: 35, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 5,
                s: [827],
              },
              { t: 35, s: [771] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 485.297, ix: 4 },
        },
        a: { a: 0, k: [281, 130, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 5,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'B_shadow.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 5, s: [0] },
            { t: 35, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 5,
                s: [827],
              },
              { t: 35, s: [771] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 507.841, ix: 4 },
        },
        a: { a: 0, k: [313, 162, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 5,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'B_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 1, s: [0] },
            {
              i: { x: [0.4], y: [1] },
              o: { x: [0.6], y: [0] },
              t: 31,
              s: [100],
            },
            {
              i: { x: [0.4], y: [1] },
              o: { x: [0.6], y: [0] },
              t: 151,
              s: [100],
            },
            { t: 161, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 1,
                s: [177],
              },
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 31,
                s: [257],
              },
              {
                i: { x: [0.1], y: [1] },
                o: { x: [0.9], y: [0] },
                t: 151,
                s: [257],
              },
              { t: 161, s: [177] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 175, ix: 4 },
        },
        a: { a: 0, k: [257, 175, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.586, -0.586],
                    [0, 0],
                    [0.585, 0.586],
                    [0, 0],
                    [-0.585, 0.586],
                    [0, 0],
                    [-0.586, -0.586],
                    [0, 0],
                    [-0.586, 0.585],
                    [0, 0],
                    [-0.585, -0.585],
                  ],
                  o: [
                    [0.586, 0.586],
                    [0, 0],
                    [-0.586, 0.586],
                    [0, 0],
                    [-0.585, -0.586],
                    [0, 0],
                    [0.586, -0.586],
                    [0, 0],
                    [0.585, 0.585],
                    [0, 0],
                    [0.586, -0.585],
                    [0, 0],
                  ],
                  v: [
                    [35.078, -18.607],
                    [35.078, -16.486],
                    [-8.347, 26.939],
                    [-10.468, 26.939],
                    [-35.078, 2.33],
                    [-35.078, 0.208],
                    [-26.745, -8.124],
                    [-24.624, -8.124],
                    [-10.468, 6.032],
                    [-8.347, 6.032],
                    [24.624, -26.94],
                    [26.745, -26.94],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [257.234, 173.472], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-45.84, 0],
                    [0, -45.84],
                    [45.84, 0],
                    [0, 45.84],
                  ],
                  o: [
                    [45.84, 0],
                    [0, 45.84],
                    [-45.84, 0],
                    [0, -45.84],
                  ],
                  v: [
                    [0, -83],
                    [83, 0],
                    [0, 83],
                    [-83, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.525490224361, 0.792156875134, 0.313725501299, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [257, 175], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 1,
      op: 91,
      st: -149,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'style-boat-market.webp',
      cl: 'webp',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [555, 355, 0], ix: 2 },
        a: { a: 0, k: [340, 340, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [185.015, 0],
                [0, -185.015],
                [-185.015, 0],
                [0, 185.015],
              ],
              o: [
                [-185.015, 0],
                [0, 185.015],
                [185.015, 0],
                [0, -185.015],
              ],
              v: [
                [340, 5],
                [5, 340],
                [340, 675],
                [675, 340],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      ip: 0,
      op: 90,
      st: -150,
      bm: 0,
    },
  ],
  markers: [],
};
