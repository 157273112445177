import srcShadow from './images/shadow.png';
import srcVerveCoffee from './images/verve-coffee.webp';
import srcZero from './images/zero-halliburton.webp';

export default {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.0.2', a: '', k: '', d: '', tc: '' },
  fr: 30,
  ip: 0,
  op: 90,
  w: 1110,
  h: 710,
  nm: 'A_webp',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 242,
      h: 242,
      u: '',
      p: srcZero,
      e: 0,
    },
    { id: 'image_1', w: 656, h: 272, u: '', p: srcShadow, e: 0 },
    {
      id: 'image_2',
      w: 464,
      h: 710,
      u: '',
      p: srcVerveCoffee,
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'zero-halliburton.webp',
      cl: 'webp',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 32, s: [0] },
            { t: 62, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.12], y: [1] },
                o: { x: [0.88], y: [0] },
                t: 32,
                s: [943],
              },
              { t: 62, s: [843] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 281, ix: 4 },
        },
        a: { a: 0, k: [121, 121, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [18.502, 0],
                [0, 0],
                [0, -18.502],
                [0, 0],
                [-18.502, 0],
                [0, 0],
                [0, 18.502],
                [0, 0],
              ],
              o: [
                [0, 0],
                [-18.502, 0],
                [0, 0],
                [0, 18.502],
                [0, 0],
                [18.502, 0],
                [0, 0],
                [0, -18.502],
              ],
              v: [
                [208.5, 0],
                [33.5, 0],
                [0, 33.5],
                [0, 208.5],
                [33.5, 242],
                [208.5, 242],
                [242, 208.5],
                [242, 33.5],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [0.114, 0],
                [0, -0.114],
                [0, 0],
                [-0.114, 0],
                [0, 0.114],
                [0, 0],
              ],
              o: [
                [-0.114, 0],
                [0, 0],
                [0, 0.114],
                [0.114, 0],
                [0, 0],
                [0, -0.114],
              ],
              v: [
                [-799.793, 104],
                [-800, 104.207],
                [-800, 104.777],
                [-799.793, 104.984],
                [-799.586, 104.777],
                [-799.586, 104.207],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 2',
        },
      ],
      ip: 32,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'A_3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 32, s: [0] },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 62,
              s: [100],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 150,
              s: [100],
            },
            { t: 160, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.12], y: [1] },
                o: { x: [0.88], y: [0] },
                t: 32,
                s: [293.725],
              },
              {
                i: { x: [0.12], y: [1] },
                o: { x: [0.167], y: [0] },
                t: 62,
                s: [373.725],
              },
              {
                i: { x: [0.833], y: [1] },
                o: { x: [0.167], y: [0] },
                t: 150,
                s: [373.725],
              },
              { t: 160, s: [293.725] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 543.84, ix: 4 },
        },
        a: { a: 0, k: [373.725, 543.84, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.842, -1.084],
                    [-0.503, -0.451],
                    [0, -1.77],
                    [1.606, -1.283],
                    [2.727, 0],
                    [2.168, 1.192],
                    [0, 0],
                    [-2.256, 0],
                    [-0.885, 0.703],
                    [0, 1.065],
                    [1.373, 0.559],
                    [1.102, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.631, 0.416],
                    [0, 0.975],
                    [0.705, 0.504],
                    [0.848, 0],
                    [1.516, -1.699],
                    [0, 0],
                    [-2.598, 0],
                    [-1.354, -1.246],
                    [0, -1.698],
                  ],
                  o: [
                    [0.991, 0.451],
                    [1.193, 1.065],
                    [0, 2.039],
                    [-1.606, 1.246],
                    [-2.582, 0],
                    [0, 0],
                    [1.752, 1.155],
                    [1.627, 0],
                    [0.814, -0.668],
                    [0, -1.283],
                    [-0.704, -0.307],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.01, 0],
                    [0.832, -0.541],
                    [0, -0.904],
                    [-0.612, -0.416],
                    [-2.112, 0],
                    [0, 0],
                    [2.003, -1.788],
                    [2.168, 0],
                    [1.191, 1.084],
                    [0, 1.933],
                  ],
                  v: [
                    [3.982, -0.95],
                    [6.228, 0.406],
                    [8.017, 4.658],
                    [5.607, 9.641],
                    [-0.894, 11.51],
                    [-8.017, 9.721],
                    [-6.906, 6.201],
                    [-0.894, 7.935],
                    [2.87, 6.878],
                    [4.091, 4.279],
                    [2.031, 1.515],
                    [-0.677, 1.055],
                    [-3.032, 1.055],
                    [-3.032, -2.276],
                    [-0.839, -2.276],
                    [1.625, -2.899],
                    [2.87, -5.174],
                    [1.814, -7.286],
                    [-0.379, -7.911],
                    [-5.822, -5.364],
                    [-7.231, -8.831],
                    [-0.326, -11.51],
                    [4.957, -9.643],
                    [6.744, -5.472],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [559.945, 544.32], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [12.915, 0],
                    [0, -12.915],
                    [-12.915, 0],
                    [0, 12.915],
                  ],
                  o: [
                    [-12.915, 0],
                    [0, 12.915],
                    [12.915, 0],
                    [0, -12.915],
                  ],
                  v: [
                    [0, -23.422],
                    [-23.422, 0],
                    [0, 23.422],
                    [23.422, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [15.12, 0],
                    [0, 15.12],
                    [-15.121, 0],
                    [0, -15.12],
                  ],
                  o: [
                    [-15.121, 0],
                    [0, -15.12],
                    [15.12, 0],
                    [0, 15.12],
                  ],
                  v: [
                    [0, 27.422],
                    [-27.422, 0],
                    [0, -27.422],
                    [27.422, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [560.057, 543.84], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'O',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-48.272, 2.311],
                    [48.271, 2.311],
                    [48.271, -2.311],
                    [-48.272, -2.311],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.903, 0.902],
                    [0, 0],
                    [0.901, -0.904],
                    [-0.904, -0.901],
                    [0, 0],
                    [0, 0],
                    [-0.904, -0.901],
                    [-0.902, 0.904],
                  ],
                  o: [
                    [0.903, -0.901],
                    [0, 0],
                    [-0.902, -0.904],
                    [-0.904, 0.901],
                    [0, 0],
                    [0, 0],
                    [-0.904, 0.902],
                    [0.901, 0.904],
                    [0, 0],
                  ],
                  v: [
                    [49.905, 1.634],
                    [49.905, -1.634],
                    [35.197, -16.341],
                    [31.929, -16.341],
                    [31.929, -13.074],
                    [45.003, 0],
                    [31.929, 13.074],
                    [31.929, 16.342],
                    [35.197, 16.342],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -1.275],
                    [-1.276, 0],
                  ],
                  o: [
                    [-1.276, 0],
                    [0, 1.276],
                    [0, 0],
                  ],
                  v: [
                    [-48.272, -2.311],
                    [-50.583, 0],
                    [-48.272, 2.311],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [465.56, 543.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.942, 0],
                    [-1.373, -1.553],
                    [0, -1.807],
                    [0.814, -1.389],
                    [3.141, -3.43],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.752, 2.113],
                    [-0.578, 0.957],
                    [0, 0.957],
                    [0.705, 0.634],
                    [0.92, 0],
                    [1.482, -2.529],
                  ],
                  o: [
                    [1.824, -2.401],
                    [2.385, 0],
                    [1.047, 1.192],
                    [0, 1.535],
                    [-1.028, 1.77],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.576, -3.719],
                    [1.336, -1.606],
                    [0.652, -1.121],
                    [0, -1.047],
                    [-0.596, -0.503],
                    [-2.149, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.918, -7.693],
                    [0.229, -11.293],
                    [5.865, -8.966],
                    [7.434, -4.469],
                    [6.216, -0.082],
                    [-0.04, 7.718],
                    [8.328, 7.718],
                    [8.328, 11.293],
                    [-8.328, 11.293],
                    [-0.339, 2.545],
                    [2.531, -1.3],
                    [3.508, -4.416],
                    [2.451, -6.935],
                    [0.177, -7.693],
                    [-5.268, -3.9],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [370.812, 544.103], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [12.915, 0],
                    [0, -12.915],
                    [-12.916, 0],
                    [0, 12.915],
                  ],
                  o: [
                    [-12.916, 0],
                    [0, 12.915],
                    [12.915, 0],
                    [0, -12.915],
                  ],
                  v: [
                    [0, -23.422],
                    [-23.423, 0],
                    [0, 23.422],
                    [23.423, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [15.121, 0],
                    [0, 15.12],
                    [-15.121, 0],
                    [0, -15.12],
                  ],
                  o: [
                    [-15.121, 0],
                    [0, -15.12],
                    [15.121, 0],
                    [0, 15.12],
                  ],
                  v: [
                    [0, 27.422],
                    [-27.423, 0],
                    [0, -27.422],
                    [27.423, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [371.071, 543.84], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'O',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-48.272, 2.311],
                    [48.271, 2.311],
                    [48.271, -2.311],
                    [-48.272, -2.311],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.904, 0.902],
                    [0, 0],
                    [0.902, -0.904],
                    [-0.903, -0.901],
                    [0, 0],
                    [0, 0],
                    [-0.903, -0.901],
                    [-0.901, 0.904],
                  ],
                  o: [
                    [0.904, -0.901],
                    [0, 0],
                    [-0.901, -0.904],
                    [-0.903, 0.901],
                    [0, 0],
                    [0, 0],
                    [-0.903, 0.902],
                    [0.902, 0.904],
                    [0, 0],
                  ],
                  v: [
                    [49.905, 1.634],
                    [49.905, -1.634],
                    [35.197, -16.341],
                    [31.929, -16.341],
                    [31.929, -13.074],
                    [45.003, 0],
                    [31.929, 13.074],
                    [31.929, 16.342],
                    [35.197, 16.342],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -1.275],
                    [-1.276, 0],
                  ],
                  o: [
                    [-1.276, 0],
                    [0, 1.276],
                    [0, 0],
                  ],
                  v: [
                    [-48.272, -2.311],
                    [-50.583, 0],
                    [-48.272, 2.311],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [276.573, 543.841], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.238, -7.678],
                    [0.745, -11.063],
                    [4.238, -11.063],
                    [4.238, 11.063],
                    [0.285, 11.063],
                    [0.285, -6.839],
                    [-3.751, -4.049],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [180.523, 544.333], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [12.916, 0],
                    [0, -12.915],
                    [-12.915, 0],
                    [0, 12.915],
                  ],
                  o: [
                    [-12.915, 0],
                    [0, 12.915],
                    [12.916, 0],
                    [0, -12.915],
                  ],
                  v: [
                    [0, -23.422],
                    [-23.422, 0],
                    [0, 23.422],
                    [23.422, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [15.121, 0],
                    [0, 15.12],
                    [-15.121, 0],
                    [0, -15.12],
                  ],
                  o: [
                    [-15.121, 0],
                    [0, -15.12],
                    [15.121, 0],
                    [0, 15.12],
                  ],
                  v: [
                    [0, 27.422],
                    [-27.422, 0],
                    [0, -27.422],
                    [27.422, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.321568638086, 0.396078437567, 0.96862745285, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [182.081, 543.84], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'O',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-5.985, 0],
                    [0, 0],
                    [0, -5.985],
                    [0, 0],
                    [5.985, 0],
                    [0, 0],
                    [0, 5.985],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [5.985, 0],
                    [0, 0],
                    [0, 5.985],
                    [0, 0],
                    [-5.985, 0],
                    [0, 0],
                    [0, -5.985],
                  ],
                  v: [
                    [-267.805, -86.997],
                    [267.805, -86.997],
                    [278.642, -76.16],
                    [278.642, 76.16],
                    [267.805, 86.997],
                    [-267.805, 86.997],
                    [-278.642, 76.16],
                    [-278.642, -76.16],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.874509811401, 0.882352948189, 0.898039221764, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.006, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [373.725, 543.84], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 32,
      op: 90,
      st: 32,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'shadow.png',
      cl: 'png',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 32, s: [0] },
            { t: 62, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.12], y: [1] },
                o: { x: [0.88], y: [0] },
                t: 32,
                s: [293.725],
              },
              { t: 62, s: [373.725] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 559, ix: 4 },
        },
        a: { a: 0, k: [328, 136, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 32,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'A_1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 30, s: [0] },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 60,
              s: [100],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 150,
              s: [100],
            },
            { t: 160, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.12], y: [1] },
                o: { x: [0.88], y: [0] },
                t: 30,
                s: [242.559],
              },
              {
                i: { x: [0.12], y: [1] },
                o: { x: [0.167], y: [0] },
                t: 60,
                s: [302.559],
              },
              {
                i: { x: [0.833], y: [1] },
                o: { x: [0.167], y: [0] },
                t: 150,
                s: [302.559],
              },
              { t: 160, s: [242.559] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 151.6, ix: 4 },
        },
        a: { a: 0, k: [302.559, 151.6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.52, 0],
                    [0.389, 0.38],
                    [0, 0],
                    [-0.771, 0.79],
                    [-0.789, -0.771],
                    [0, 0],
                    [0.771, -0.79],
                  ],
                  o: [
                    [-0.504, 0],
                    [0, 0],
                    [-0.791, -0.772],
                    [0.773, -0.79],
                    [0, 0],
                    [0.791, 0.772],
                    [-0.392, 0.401],
                  ],
                  v: [
                    [33.802, 35.002],
                    [32.406, 34.433],
                    [-35.199, -31.571],
                    [-35.234, -34.4],
                    [-32.406, -34.434],
                    [35.199, 31.571],
                    [35.234, 34.399],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [300.725, 153.33], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [25.364, 0],
                    [0, -25.364],
                    [-25.364, 0],
                    [0, 25.364],
                  ],
                  o: [
                    [-25.364, 0],
                    [0, 25.364],
                    [25.364, 0],
                    [0, -25.364],
                  ],
                  v: [
                    [0, -46],
                    [-46, 0],
                    [0, 46],
                    [46, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [27.57, 0],
                    [0, 27.57],
                    [-27.57, 0],
                    [0, -27.57],
                  ],
                  o: [
                    [-27.57, 0],
                    [0, -27.57],
                    [27.57, 0],
                    [0, 27.57],
                  ],
                  v: [
                    [0, 50],
                    [-50, 0],
                    [0, -50],
                    [50, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [302.559, 151.6], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.104, 0],
                    [0, 0],
                    [0, 1.104],
                    [-1.104, 0],
                    [0, 0],
                    [0, -1.104],
                  ],
                  o: [
                    [0, 0],
                    [-1.104, 0],
                    [0, -1.104],
                    [0, 0],
                    [1.104, 0],
                    [0, 1.104],
                  ],
                  v: [
                    [21.091, 2],
                    [-21.091, 2],
                    [-23.091, 0],
                    [-21.091, -2],
                    [21.091, -2],
                    [23.091, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [303.282, 163.237], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.104, 0],
                    [0, 0],
                    [0, 1.104],
                    [-1.104, 0],
                    [0, 0],
                    [0, -1.104],
                  ],
                  o: [
                    [0, 0],
                    [-1.104, 0],
                    [0, -1.104],
                    [0, 0],
                    [1.104, 0],
                    [0, 1.104],
                  ],
                  v: [
                    [21.091, 2],
                    [-21.091, 2],
                    [-23.091, 0],
                    [-21.091, -2],
                    [21.091, -2],
                    [23.091, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [303.282, 153.055], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.104, 0],
                    [0, 1.104],
                    [0, 0],
                    [0, 0],
                    [-0.895, 0.647],
                    [-0.647, -0.896],
                    [0, 0],
                    [0, 0],
                    [-0.94, -0.58],
                    [0.58, -0.94],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.104, 0],
                    [0, 0],
                    [0, 0],
                    [-0.647, -0.895],
                    [0.896, -0.647],
                    [0, 0],
                    [0, 0],
                    [0.58, -0.941],
                    [0.94, 0.58],
                    [0, 0],
                    [0, 0],
                    [0, 1.104],
                  ],
                  v: [
                    [1.244, 28.909],
                    [-0.756, 26.909],
                    [-0.756, -2.912],
                    [-17.257, -25.737],
                    [-16.808, -28.529],
                    [-14.016, -28.081],
                    [1.112, -7.156],
                    [13.934, -27.958],
                    [16.686, -28.611],
                    [17.339, -25.859],
                    [3.244, -2.993],
                    [3.244, 26.909],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.752941191196, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [302.92, 153.055], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 30,
      op: 90,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'verve-coffee.webp',
      cl: 'webp',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [325, 0, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 0,
            k: {
              i: [
                [27.062, 0],
                [0, 0],
                [0, -27.074],
                [0, 0],
                [-27.062, 0],
                [0, 0],
                [0, 27.074],
                [0, 0],
              ],
              o: [
                [0, 0],
                [-27.062, 0],
                [0, 0],
                [0, 27.074],
                [0, 0],
                [27.062, 0],
                [0, 0],
                [0, -27.074],
              ],
              v: [
                [413, 0],
                [49, 0],
                [0, 49.022],
                [0, 660.978],
                [49, 710],
                [413, 710],
                [462, 660.978],
                [462, 49.022],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      ip: 0,
      op: 181,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
